import * as React from 'react';
import IconFocus from './icon_focus';
import IconEmergency from './icon_emergency';


const SIZE = 25;
const offsetFocus = -25;

function IconSphere(props) {
  const {offset, rotate, color, focus, onClick, emergency, showMarker} = props;
  const defaultOffset = -SIZE / 2;

  return(
    <>
      <svg
        height={20}
        width={20}
        style={{
          cursor: 'pointer',
          stroke: 'none',
          opacity: focus || showMarker ? .999 : .45,
          transform: `translate(-10px,-10px) rotate(0deg) `,
          overflow: 'visible'
        }}
        onClick={onClick}
      >
        <circle cx={"20"} cy={"20"} r={"10"} fill={color}/>
      </svg>

      { focus && 
        <IconFocus objectSize={SIZE} offset={{x: offset.x + 12 + offsetFocus, y: offset.y + 17}} />
      }
      {emergency && <IconEmergency  objectSize={SIZE} offset={{x: offset.x+12 + offsetFocus, y: offset.y+17}} />}
    </>
  );
}

export default React.memo(IconSphere);
