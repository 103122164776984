import * as React from 'react';
import API from './API.js';
import Heat from './heat.js';
import Device from './device';
import {
  PRO_RACEMANEGEMENT_HOSTNAME,
  PRO_GOAL_CHECKER_HOSTNAME,
  PRO_websocketURL,
  STG_RACEMANEGEMENT_HOSTNAME,
  STG_GOAL_CHECKER_HOSTNAME,
  STG_websocketURL,
  DEV_RACEMANEGEMENT_HOSTNAME,
  DEV_GOAL_CHECKER_HOSTNAME,
  DEV_websocketURL,
} from './config.js';

import {initializeWebsocket} from './websocket.js';

console.log('initializing')

//URLよりパラメータを取得
export function URLParams() {
  var url = window.location.search;
  var query = url.substr(1);
  var result = {};
  query.split("&").forEach(function(part) {
    var item = part.split("=");
    result[item[0]] = decodeURIComponent(item[1]);
  });
  return result;
}

var mode = URLParams()['mode']
console.log("MODE=", mode)

var view = URLParams()['view']
console.log("VIEW=", view)

var ranking = URLParams()['ranking']

var env = URLParams()['env']
if (!env){
  alert("Invalid env param.")
}
console.log("ENV=", env)

var ele_offset = URLParams()['ele_offset'] //Elevationのオフセット

// ReplayData JSON URL
var tenant_id = URLParams()['tenant_id']
if (!env){
  tenant_id=51
}
console.log("tenant_id=", tenant_id)



var RACEMANEGEMENT_HOSTNAME = PRO_RACEMANEGEMENT_HOSTNAME()
var GOAL_CHECKER_HOSTNAME = PRO_GOAL_CHECKER_HOSTNAME()
var websocketURL = process.env.REACT_APP_WEBSOCKET_URL + tenant_id;

if (env == "staging"){
  RACEMANEGEMENT_HOSTNAME = STG_RACEMANEGEMENT_HOSTNAME()
  GOAL_CHECKER_HOSTNAME = STG_GOAL_CHECKER_HOSTNAME()
}
else if(env == "development"){
  RACEMANEGEMENT_HOSTNAME = DEV_RACEMANEGEMENT_HOSTNAME()
  GOAL_CHECKER_HOSTNAME = DEV_GOAL_CHECKER_HOSTNAME()
}

// Get heatid from path
var heatId = URLParams()['heat_id']
if ((isNaN(heatId))&& (mode != "replay")){
  alert("Invalid URL. Heat id isn't specified")
}

export function getHeatId(){
  return heatId
}

var g_heat = null;
export function getHeat(){
  return g_heat
}

// token
var token = URLParams()['token']
if ((token == undefined) && (mode != "replay")){
  alert("Invalid URL. token isn't specified")
}

// ReplayData JSON URL
var dataURL = URLParams()['data']
if ((mode == "replay") && (!dataURL)){
  alert("Invalid URL. dataURL isn't specified")
}

// host name
if (!RACEMANEGEMENT_HOSTNAME){
  alert("Invalid RACEMANEGEMENT_HOSTNAME.")
}
console.log("RMS:",RACEMANEGEMENT_HOSTNAME)
console.log("GOAL:",GOAL_CHECKER_HOSTNAME)
console.log("RANK:",process.env.REACT_APP_RANKING_URL)
console.log("WS:",websocketURL)

//----------------------------------------------------------

export function is_mode(){
 return mode;
}

export function is_triathlon_view(){
  return view === "triathlon" ;
  //return true;
}

export function is_individual_view(){
  return (view === "individual") || (view === "terrain");
}

export function is_ranking(){
	if ((ranking == undefined) && (ranking != "1")){
		return false
	} else {
		return true
	}
}

export function is_terrain_view(){
  return view === "terrain";
}
export function getReplayDataUrl(){
 return dataURL;
}

export function wsURL(){
 return websocketURL;
}

export function getEleOffset(){
  if(Math.abs(ele_offset) > 0)
    return Number(ele_offset);
  else
    return 0;
}

var heat = null
var lines = []
var athletes = []
var window_disp;

//initialize()
export function init(setDevices, setCenter, setOnline, setWind, setCourse, setHeat, setHeatName){
  initializeHeat(setDevices, setCenter, setOnline, setWind, setCourse, setHeat, setHeatName);
}

function initializeHeat(setDevices, setCenter, setOnline, setWind, setCourse, setHeat, setHeatName){
    let success =  function(ret) {
      let data = ret.data;
			setHeat(data); //speed 表示
      heat = new Heat(data.id, data.status, data.course, data.started_at, data.ended_at, data.sensors, data.wind_direction, data.max_wind, data.min_wind, data.name, data.number_qualifiers);
      if (heat.status != 'started'){
        if (heat.status == 'finished'){
          alert('このヒートはすでに終了しました。');
        }else{
          alert('このヒートはまだ開始されていません');
        }
      }
      if (!heat.isActive()){
        alert('このヒートにセンサー情報が紐づけられていません');
      }
      g_heat = heat
      // Set map center
      setCenter(heat.center())

			// Set checkpoints
			setCourse({
			  net             : false,//強制不使用
				goal_start_lat  : data.course.goal_start_lat,
				goal_start_long : data.course.goal_start_long,
				goal_end_lat    : data.course.goal_end_lat,
				goal_end_long   : data.course.goal_end_long,
				checkpoints     : data.course.checkpoints,
				geojson         : data.course.geojson != "" ?  JSON.parse(data.course.geojson):"",
				lap             : data.course.lap,
			});

      //Athlete&Sensor
      initializeDevices(setDevices, setOnline)
      //Wind
      if(heat.wind_direction && heat.max_wind){
        setWind({
          "wind_direction" : heat.wind_direction,
          "max_wind" : heat.max_wind,
          "min_wind" : heat.min_wind} );
      }

      //HeatName
      setHeatName(heat.name);
    }
    let error = function(data){
      console.log(data);
      alert('failed to get Heat data');
    }

    API.getHeat({heat_id:heatId, host:RACEMANEGEMENT_HOSTNAME, token:token, onSuccess:success, onError:error});
}


//選手の初期化
function initializeDevices(setDevices, setOnline){

  let success = function(res) {
    let data = res.data;
    //Deviced
    let deviceMap = new Map();
    data.map(function(d, i) {
      deviceMap.set( d.serial, new Device(d.serial, d));
    });
    setDevices(deviceMap);

    //initialize websocket
    initializeWebsocket(deviceMap, setDevices, setOnline, token);
  }
  let error = function(res){
    alert('failed to get athletes data:', res)
  }

  API.getHeatSensors({heat_id:heatId, host:RACEMANEGEMENT_HOSTNAME, token:token, onSuccess:success, onError:error});
  //initializeLines()
}

var heatTime = null;
//1秒毎に呼び出す処理
export function updateHeatTime(setHeatTime, setHeatStatus){
		//1S
		let f = function(){
			if(heatTime){
				let t = dateSubstract(new Date(), heatTime)
				//console.log(t)
				setHeatTime(t)

				var ms = new Date() - heatTime
				ms = Math.floor(ms / 1000)
				//4
				if((-240 <= ms) && (ms < -180))
					setHeatStatus("heat-red")
				//3
				else if((-180 <= ms) && (ms < -120))
					setHeatStatus("heat-none")
				//2
				else if((-120 <= ms) && (ms < -60))
					setHeatStatus("heat-yellow")
				//1
				else if((-60 <= ms) && (ms < 0))
					setHeatStatus("heat-none")
				//Start
				else if((ms >= 0))
					setHeatStatus("heat-green")
				else
					setHeatStatus("heat-none")
			}
		}
		//5S
		let success =  function(ret) {
			let data = ret.data;
			let ht = Date.parse(data.started_at)
			heatTime = ht
		}
		let error = function(data){
			console.log(data);
			alert('failed to get Heat data');
		}
		API.getHeat({heat_id:heatId, host:RACEMANEGEMENT_HOSTNAME, token:token, onSuccess:success, onError:error})
		setInterval(()=>(API.getHeat({heat_id:heatId, host:RACEMANEGEMENT_HOSTNAME, token:token, onSuccess:success, onError:error})), 5000);
		setInterval(()=>(f()), 1000);
}

//###########################################
// Calculate Elapsed Time
// heat's start_time is always 4 minutes before actual start
// So I substract additional 4 minute from start time
function dateSubstract(from, to){
  //var millisecond = from - to - 4 * 60 * 1000
  var millisecond = from - to
  millisecond = Math.floor(millisecond / 1000) * 1000
  if (millisecond < 0){
    return "-" + msToHMS(Math.abs(millisecond))
  }
  else {
    return msToHMS(millisecond)
  }
}

function msToHMS( ms ) {
    // 1- Convert to seconds:
    var seconds = ms / 1000;
    // 2- Extract hours:
    var hours = parseInt( seconds / 3600 ); // 3,600 seconds in 1 hour
    seconds = seconds % 3600; // seconds remaining after extracting hours
    // 3- Extract minutes:
    var minutes = parseInt( seconds / 60 ); // 60 seconds in 1 minute
    // 4- Keep only seconds not extracted to minutes:
    seconds = parseInt(seconds % 60)
    return (zeroPad(hours)+":"+zeroPad(minutes)+":"+zeroPad(seconds));
}

function zeroPad(s){
  return String(s).padStart(2, '0')
}

//###########################################


/*
function initializeLines(){
  // Create Lines Between Buoys
  new ElapsedTime(heat.started_at)
  var points = []
  for (var key in heat.course.coordinates){
    var coord = heat.course.coordinates[key]
    var point = [coord.lat, coord.long]
    new Buoy(point, coord.name)
    points.push(point)
  }
  new PolyLine(points, {color: 'blue', weight: 4, opacity: 0.2})
  // Create Line between Goal
  new PolyLine([heat.goalStartCoordinate(), heat.goalEndCoordinate()], {color: 'red', weight: 4, opacity: 0.2})
  // Create Line of Checkpoints
  initializeWebsocket()
  hideWindow()
}


//フォーカスFeature
function athleteClickedonBroadcast(obj){

    var keys = Object.keys(heat.devices)

    if(obj.prop('checked')){

        for(i=0; i < keys.length; i++){
          if (keys[i] == obj.attr("id")){
            heat.devices[keys[i]].highlight = true
            heat.devices[keys[i]].focus()
          }
        }
    }else{
        var id = obj.attr("id")
        for(i=0; i < keys.length; i++){
          if (keys[i] == obj.attr("id")){
            heat.devices[keys[i]].highlight = false
            heat.devices[keys[i]].disFocus()
          }
        }
    }
}
*/

