import React, { useState } from "react";

import PanelContent from "./panel_content";
import PanelItem from "./panel_item";

const AERIAL = 1;
const LIGHT = 2;
const DARK = 3;
const YOKOHAMA = 4;

function PanelContentMap(props) {
  const {setStyle} = props;
  const [active, setActive] = useState(YOKOHAMA);

  return (
    <PanelContent title="地図の種類">
      <PanelItem imagePath={"/icon_interface/aerial.jpg"} title={"航空写真"} isActive={active === AERIAL}
        onClick={()=>{
          //setStyle('mapbox://styles/i01210ml/cljpbvcbm005701rdbj1cezho')//3D
          setStyle('mapbox://styles/i01210ml/clzuwwncx00k101r36jubaxsn')//Classic
          setActive(AERIAL)
          }}/>
      <PanelItem imagePath={"/icon_interface/light.jpg"} title={"ライト"} isActive={active === LIGHT}
        onClick={()=>{
          //setStyle('mapbox://styles/mapbox/light-v9')
          setStyle('mapbox://styles/i01210ml/cm7mroetf002p01rdftou0k7t')
          setActive(LIGHT)
          }}/>
      <PanelItem imagePath={"/icon_interface/dark.jpg"} title={"ダーク"} isActive={active === DARK}
        onClick={()=>{
          //setStyle('mapbox://styles/mapbox/dark-v9')
          setStyle('mapbox://styles/i01210ml/cm883e37u00cf01sqd0cxbdl5')
          setActive(DARK)
          }}/>
      <PanelItem imagePath={"/icon_interface/color.jpg"} title={"オリジナル"} isActive={active === YOKOHAMA}
        onClick={()=>{
          setStyle('mapbox://styles/i01210ml/clg571dks000501qna35qk8uh')
          setActive(YOKOHAMA)
          }}/>
    </PanelContent>
  );
}

export default PanelContentMap;
