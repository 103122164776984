import * as React from 'react';
import {useState, useEffect, useRef} from 'react';
//import Map, {Source, Layer} from 'react-map-gl';
import Map, {
  Popup,
  NavigationControl,
  FullscreenControl,
  ScaleControl,
  GeolocateControl,
  Source,
  Layer,
} from 'react-map-gl';


import 'mapbox-gl/dist/mapbox-gl.css';

import {lineLayer, StartGoalLineLayer, SymbolLayer} from './lineLayer.js';
import Online from './online.js';

import useRouteVisibility from '../../hooks/useRouteVisibility.js';

import YokohamaIcons from './yokohamaIcons.js';
import CourseMap from './course_map.js';
import Menu from '../menu/menu.js';
import StartGoalFlag from '../marker/start_goal_flag.js';
import Sups from '../marker/sups.js';
import WindInfo from './wind_info.js';

import {getElevation, interpolatePoints, getNearestElevation } from './get_elevation.js';
import ElevationChart from './elevation_view.js';
import {hidden_tooltip, hidden_buttons } from '../../helpers';

const TOKEN = 'pk.eyJ1IjoiaTAxMjEwbWwiLCJhIjoiY2t1NWZ0ZGNhNHp1eTJ2cDMxNXV0OHAwNCJ9.SqRPyXL7O65p-osCqqmp4Q'; // Set your mapbox token here
const geolocateStyle = {
  top: 0,
  left: 0,
  padding: '10px'
};
const fullscreenControlStyle = {
  top: 36,
  left: 0,
  padding: '10px'
};
const navStyle = {
  top: 72,
  left: 0,
  padding: '10px'
};
const scaleControlStyle = {
  bottom: 36,
  left: 0,
  padding: '10px'
};
const pointLayer = {
  type: 'circle',
  paint: {
    'circle-radius': 10,
    'circle-color': '#007cbf'
  }
};

const urlParams = new URLSearchParams(window.location.search);
//モジュールロード
var heatCenteredTime = new Date();

//高度データ２周処理を防止
let isProcessing = false; // 処理中かどうかを示すフラグ

function MapView(props) {
	const mapRef = useRef();
  // Props
  const {devices, center, style, online, height, wind, showWindLine, windLine, setWindLine, course, setStyle, is_triathlon_view, handleClickMarker, showElevation, elevationZoom, ranking, clearDevices, youtube} = props;
  const [popupInfo, setPopupInfo] = useState(null);
  const [bearing, setBearing] = useState(urlParams.get("bearing")? Number(urlParams.get("bearing")): 0);
  const [pitch, setPitch] = useState(urlParams.get("pitch")? Number(urlParams.get("pitch")): 0);
  const [showCheckpoint, setShowCheckpoint] = useState(false);
	const [mapLoaded, setMapLoaded] = useState(false);
	const [elevation, setElevation] = useState([]);
	const [elevationLoaded, setElevationLoaded] = useState(false);
	const [elevationColor, setElevationColor] = useState("#FFFFFF");
	const [showElevationIndexes, setShowElevationIndexes] = useState([]);
	const [prevMap, setPrevMap] = useState(style);
  const [focusFlg, setFocusFlg] = useState(false);//一人でもフォーカスした選手がいるかどうか

  // カメラをフォーカスするかを判定するフラグ。
  // フォーカス時に地図の中心をカメラに合わせる場合、
  // 「選手のフォーカス機能」と同じロジック(main.js内handleChangeSelectedDevice関数 + 下に続くuseEffectによるcenter変更)にした方が良いかも。
  const [focusCamera, setFocusCamera] = useState(false);

  // フルスクリーン判定用の状態を追加
  const [isFullscreen, setIsFullscreen] = useState(false);

  //--- Mapロード完了後,実行-------------------------------------
	const handleMapLoad = () => {
    setMapLoaded(true);

		if(!showElevation){
			setElevationLoaded(true)
		}

    //アイコンをロードする処理
    const map = mapRef.current.getMap();
    map.on("styledata", () => {
      loadIconImage();
    });
	};

  // アイコンをロードする処理
  const loadIconImage = () => {

    const map = mapRef.current.getMap();
    map.loadImage('/road_race/kom.png', (error, image) => {
    if (error) {
        console.error("Image loading error:", error); // エラーログ出力
      } else {
        if (!map.hasImage('kom-icon')) {
          map.addImage('kom-icon', image);
        }
      }
    });

    map.loadImage('/road_race/sprint.png', (error, image) => {
    if (error) {
        console.error("Image loading error:", error); // エラーログ出力
      } else {
        if (!map.hasImage('sprint-icon')) {
          map.addImage('sprint-icon', image);
        }
      }
    });

  }

  // State
  //---- normal react-map-gl -----------------

  const [viewState, setViewState] = useState({
		latitude: 0,
		longitude: 0,
            zoom: 3,
    pitch: pitch,
  });

  // フルスクリーン状態の監視 ------------------------
  useEffect(() => {
    const checkFullscreen = () => {
      if(hidden_buttons()) {
        setIsFullscreen(document.fullscreenElement !== null);
      }
    };

    document.addEventListener("fullscreenchange", checkFullscreen);
    return () => {
      document.removeEventListener("fullscreenchange", checkFullscreen);
    };
  }, []);
  // Heat Center------------------------------------------
  useEffect(() => {
    if (center[0] === 35.28167445342043 && center[1] === 139.67306039427112) return;
		setViewState({
			...viewState,
			latitude: center[0],
			longitude: center[1],
            //zoom: 3,
            zoom: showElevation? elevationZoom : 3,
			bearing: wind?wind.wind_direction - 360 + bearing:0
		});
		if(mapRef.current && elevationLoaded){
			setTimeout(()=>{mapRef.current.flyTo({center: [center[1], center[0]], zoom:15, duration: 3000})}, 1000);
		}
  }, [center]);

  useEffect(() => {

    if (center[0] === 35.28167445342043 && center[1] === 139.67306039427112) return;
		if(mapRef.current && elevationLoaded ){
			setTimeout(()=>{mapRef.current.flyTo({center: [center[1], center[0]], zoom:15, duration: 3000})}, 1000);
		}
  }, [elevationLoaded]);


   // Marker Center ------------------------------------------
  let focus_flg = false;
  const [lastFocus, setLastFocus] = useState(null);
  useEffect(() => {
		//HeatのCenteringが終わるまで5秒間、Waitig
    //これがないとview=triathlon時に、HeatのCenteringが終わる前に、MarkerのCenteringが走ってしまう。
		if(new Date() - heatCenteredTime < 2000){
			return;
		}
    //Selected Center
    if(devices){
      let new_center = [0,0];
      let count = 0;
	  let indexes = []//road race標高表示位置のリスト
      let flg = false;
      devices.forEach((device, i)=>{
        if(device.ischecked && device.lat && device.lon){

          if(!hidden_tooltip()){
            new_center[0] = new_center[0] + device.lat;
            new_center[1] = new_center[1] + device.lon;
            count = count + 1;
            flg = true;
            if(String(device.id) !== String(lastFocus)){
                focus_flg = true
            } else {
                focus_flg = false
            }
            setLastFocus(device.id)
          }

          //road race標高表示位置の計算する
          if(showElevation){
              const index = getNearestElevation(elevation, device.lon, device.lat);
              indexes.push( {index:index, color:device.color_code} );
          }
        }
      });

	  setShowElevationIndexes(indexes);
      setFocusFlg(flg);

      if(count > 0){
        new_center[0] = new_center[0] / count;
        new_center[1] = new_center[1] / count;
				if((new_center[0] <= 90) && (new_center[0] >= -90)){
					if(focus_flg){ //選択時
						mapRef.current.flyTo({center: [new_center[1], new_center[0]], duration: 1500, zoom:16});
						heatCenteredTime = new Date();//ズームの時間を稼ぐため
					} else { //常時
						mapRef.current.flyTo({center: [new_center[1], new_center[0]], duration: 1500});
					}
				}
      }
    }
  }, [devices]);

  // route display on/off
  const [visibleRoutes, setVisibleRoutes] = useRouteVisibility(course ? course.geojson : null);
  //Start-Goalライン
  const [StartGoal, setStartGoal] = useState(null);
  // スタート地点の座標を取得するために用意
  const [startTwoCoords, setStartTwoCoords] = useState(null);
  // ゴール地点の座標を取得するために用意
  const [goalTwoCoords, setGoalTwoCoords] = useState(null);
  useEffect(() => {
      let f = [];
      let goal_boat;
      let goal_buoy;
      let start_boat;
      let start_buoy;
      let wind_line = [];
      devices.forEach((device, i)=>{
        if(device.category == 3 && device.disp_name == "S")
          start_buoy = device;
        if(device.category == 4 && device.disp_name == "S")
          start_boat = device;
        if(device.category == 3 && device.disp_name == "G")
          goal_buoy = device;
        if(device.category == 4 && device.disp_name == "G")
          goal_boat = device;
        //WindLine
        if(wind && showWindLine && windLine && device.id == windLine.deviceId){
          const L = 6371 * 2 * Math.PI / 360 * 1000;
          const lineLength = 1000;
          let x = device.lon;
          let y = device.lat;
          let x1 = x + (lineLength / L) - x;
          let x2 = x - (lineLength / L) - x;
          let deg = -1 * wind.wind_direction * (Math.PI / 180)
          let pos1 = {lon: x1*Math.cos(deg)-0*Math.sin(deg),
                      lat: 0*Math.cos(deg) +x1*Math.sin(deg)};
          pos1.lon = pos1.lon + x;
          pos1.lat = pos1.lat + y;
          let pos2 = {lon: x2*Math.cos(deg)-0*Math.sin(deg),
                      lat: 0*Math.cos(deg) +x2*Math.sin(deg)};
          pos2.lon = pos2.lon + x;
          pos2.lat = pos2.lat + y;
          wind_line.push([pos2.lon, pos2.lat]);
          wind_line.push([pos1.lon, pos1.lat]);
          f.push({type: "Feature", properties:{name: "", color: device.color, width: 2, opacity: 0.9},
                  geometry:{type:"LineString",coordinates:[ wind_line[0], wind_line[1] ]}});
        }
      });
      //StartLine
      if(start_boat && start_buoy)
        f.push({type: "Feature", properties:{name: "START", color: "#FFFFFF", width: 4, opacity: 1},
                geometry:{type:"LineString",coordinates:[ [start_boat.lon, start_boat.lat],[start_buoy.lon, start_buoy.lat] ]}});
      //GoalLine
      if(goal_boat && goal_buoy)
        f.push({type: "Feature", properties:{name: "GOAL", color: "#FF0000", width: 4, opacity: 1},
                geometry:{type:"LineString",coordinates:[ [goal_boat.lon, goal_boat.lat],[goal_buoy.lon, goal_buoy.lat] ]}});
      //Static Goal Line
      if(course){
				//Goal
        f.push({type: "Feature", properties:{name: "GOAL", color: "#FF0000", width: 4, opacity: 1},
                geometry:{type:"LineString",coordinates:[ [course.goal_start_long, course.goal_start_lat],[course.goal_end_long, course.goal_end_lat] ]}});
        setGoalTwoCoords({coord1: {latitude: course.goal_start_lat, longitude: course.goal_start_long}, coord2: {latitude: course.goal_end_lat, longitude: course.goal_end_long}});
				//Checkpoints
      	course.checkpoints.forEach((cp, i)=>{
					//1個目がスタートかつNET
					if((course.net && i==0) || cp.check_name == "START") {
						//line color:white(start)
        		f.push({type: "Feature", properties:{name: "START", color: "#FFFFFF", width: 4, opacity: 1},
                geometry:{type:"LineString",coordinates:[ [cp.s_lon, cp.s_lat],[cp.e_lon, cp.e_lat] ]}});
            setStartTwoCoords({coord1: {latitude: cp.s_lat, longitude: cp.s_lon}, coord2: {latitude: cp.e_lat, longitude: cp.e_lon}});
					}else{
						//line color:Red
						if((cp.check_name == "GS") || (cp.check_name == "GB") || (cp.check_name == "GR")){
							f.push({type: "Feature", properties:{name: cp.check_name, color: "#FF0000", width: 1, opacity: 1},
									geometry:{type:"LineString",coordinates:[ [cp.s_lon, cp.s_lat],[cp.e_lon, cp.e_lat] ]}});
						} else {
							if(showCheckpoint){
								//line color:green
								f.push({type: "Feature", properties:{name: cp.check_name, color: "#88FF88", width: 1, opacity: 0.9},
										geometry:{type:"LineString",coordinates:[ [cp.s_lon, cp.s_lat],[cp.e_lon, cp.e_lat] ]}});
							}
						}
					}
				})
			}
      setStartGoal({
        type: 'FeatureCollection',
        features: f
      });
  }, [devices, windLine, showCheckpoint]);

  const [geojson, setGeojson] = useState({
      type: 'FeatureCollection',
      features: [
        //{type: "Feature", properties:{name:"(ROUTE 11/12)",color:"#00aeef"}, geometry:{type:"LineString",coordinates:[[139.65, 35.7],[139.5, 35.9]]}},
      ]
  });
  //GeoJsonの更新
  useEffect(() => {
      let f = [];
      devices.forEach((device, i)=>{
        if(focusFlg){
          if(device.lines.length > 0 && device.ischecked){
            f.push(
              {type: "Feature", properties:{name: device.lastname, color: device.color}, geometry:{type:"LineString",coordinates:device.lines}},
            );
          }
        }else{
          if(device.lines.length > 0){
            f.push(
              {type: "Feature", properties:{name: device.lastname, color: device.color}, geometry:{type:"LineString",coordinates:device.lines}},
            );
          }
        }
      });
      f.push( {type: "Feature", properties:{name:"This needs for animation",color:"#000000"}, geometry: pointData});// Dummy data
      setGeojson({
        type: 'FeatureCollection',
        features: f
      });
  }, [devices]);

  //--- ElevationData作成後にもとに戻す-------------------------------------
	useEffect(() => {//Revert
		if (elevationLoaded) {
			setStyle(prevMap); // revert map
		}
	}, [elevationLoaded]); // elevationLoaded が変わる度に実行

  //--- ElevationDataの作成-------------------------------------
	useEffect(() => {
    const loadTerrainMap = async (course) => {
      if (isProcessing || elevationLoaded) {
        return; // 処理中、または既に elevationLoaded が true なら何もしない
      }
      
      isProcessing = true; // 処理を開始
      
      try {
        setStyle('mapbox://styles/i01210ml/cljpbvcbm005701rdbj1cezho'); // terrain include elevation data
        await wait(1000); // 1秒待機
        await fetchElevations(course); // fetchElevations の完了を待つ
      } finally {
        if(elevation.length == 0){
          isProcessing = false; // 処理完了後にフラグを戻す
        }else{
        }
      }
    };

    // 3秒待機するための Promise ラップ関数
    const wait = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

		const fetchElevations = async (course) => {
			if (mapLoaded) {
				if (course && course.geojson) {
					if (course.geojson.features[0].geometry) {
						let elevationDatas = [];
						const coordinates = course.geojson.features[0].geometry.coordinates;
						for (let i = 0; i < coordinates.length - 1; i++) {
							const from = coordinates[i];
							const to = coordinates[i + 1];
							const points = interpolatePoints(from[1], from[0], to[1], to[0], 50);
							let j = 0;
							for (const p of points) {
                if(!(isNaN(p[0])||isNaN[1])){
                  const elevationData = await getElevation(p[0], p[1], mapRef.current);
                  elevationDatas.push({ index: j, lon: p[0], lat: p[1], elevation: Math.trunc(elevationData) });
                  j = j + 1;
                }
							}
						}
						setElevation(elevationDatas);
						setElevationColor(course.geojson.features[0].properties.color);
						setElevationLoaded(true);
					}
				}
			}
		};

		if(showElevation){
			loadTerrainMap(course);
		}
	}, [mapLoaded, course, devices]);


  //--- Animation発生装置(何故更新されいのか不明-------------------------------------
  const [pointData, setPointData] = useState(null);
  let pointOnCircle = function({center, angle, radius}) {
  return {
    type: 'Point',
    coordinates: [center[0] + Math.cos(angle) * radius, center[1] + Math.sin(angle) * radius]
  };
  }
  useEffect(() => {
    const animation = window.requestAnimationFrame(() =>
      setPointData(pointOnCircle({center: [-100, 0], angle: Date.now() / 1000, radius: 20}))
    );
    return () => window.cancelAnimationFrame(animation);
  });

	let onMove = function(arg){
		setBearing(arg.viewState.bearing)
		setPitch(arg.viewState.pitch)
		setViewState(arg.viewState)
	}

  //自動フォーカスを解除
	let onDrag = function(arg){
    clearDevices();
  }

  return (
	<>
    <Map
			style={{height:height}}
			ref={mapRef}
      mapboxAccessToken={TOKEN}
			width="100%"
			height={height}
      {...viewState}
      onMove={onMove}
      onDragStart={onDrag}
      mapStyle={style}
			onLoad={handleMapLoad}
    >

			<WindInfo wind={wind} bearing={bearing} />
			<Source type="geojson" data={geojson}>
				<Layer {...lineLayer} />
			</Source>
			<Source type="geojson" data={StartGoal}>
				<Layer {...StartGoalLineLayer} />
				<Layer {...SymbolLayer} />
			</Source>
			{ course && course.geojson && <CourseMap visibleRoutes={visibleRoutes} courseGeojson={course.geojson} />}
			<YokohamaIcons
				zoomLevel={viewState.zoom}
				viewBearing={bearing}
			/>
			<StartGoalFlag startTwoCoords={startTwoCoords} goalTwoCoords={goalTwoCoords}/>

      { (!isFullscreen ) && (
        <>
          <GeolocateControl position={"top-left"} />
          <FullscreenControl position={"top-left"} />
          <NavigationControl position={"top-left"} />
          <Menu
            setStyle={setStyle}
            visibleRoutes={visibleRoutes}
            setVisibleRoutes={setVisibleRoutes}
            focusCamera={focusCamera}
            setFocusCamera={setFocusCamera}
            showCheckpoint={showCheckpoint}
            setShowCheckpoint={setShowCheckpoint}
          />
          <ScaleControl />
        </>
      )}


			<Sups devices={devices} ranking={ranking} bearing={bearing} setWindLine={setWindLine} focusCamera={focusCamera} is_triathlon_view={is_triathlon_view} handleClickMarker={handleClickMarker} focusFlg={focusFlg} />

		</Map>

		{ showElevation && 
			<ElevationChart data={elevation} activeIndexes={ showElevationIndexes } color={elevationColor} youtube={youtube} />
		}

		</>
  );
}

export default MapView;
