import * as React from 'react';
import {useState, useEffect} from 'react';
import { Marker } from 'react-map-gl';
import IconArrow from './icon_arrow';
import IconSphere from './icon_sphere';
import IconSup from './icon_sup';
import {greenBack, marker_type, font_size} from '../../helpers';

const posDiffDefault = {x: 0, y: 0};
const posDiffName = {x: 0, y: -14.5};
const posDiffTooltip = {x: 28, y: -42};
const posDiffFocus = {x: 0, y: -15};
const posDiffFocusNoName = {x: 5, y: -5};
const posDiffFocusTooltip = {x: 28, y: -42};

// Important for perf: the markers never change, avoid rerender when the map viewport changes
function Animation(props) {
  const {id, position, no, name, speed, time, color, focus, showMarker = true, bearing, setWindLine,  duration, category, animate_flg, is_triathlon_view, emergency, handleClickMarker, rank, focusFlg} = props;
  const [currentPos, setCurrentPos]   = useState({lat:0, lon:0, angle:0});
  const [showTooltip, setShowTooltip] = useState(false);
  const [posDiff, setPosDiff] = useState(posDiffDefault);
  const [prevTime, setPrevTime]   = useState(null);
  const showName = focus || showTooltip || !focusFlg;

  //Duration
  let _duration = 1000 // initial value
  if(duration)
    _duration = duration

  //positionが更新のたびに起動
  useEffect(() => {

    if (animate_flg === false){
      return;
    }

    //更新がなければ処理しない
    if( time == prevTime ){
      //console.log("same time.")
      return;
    } else{
      setPrevTime( time );
    }

    //デバイスを移動
    if((Math.abs(position.lat) < 180 )){
      //パラメタ
      //移動距離
      let d_angle = position.angle -  currentPos.angle;

      if(Math.abs(d_angle) > 180){
        if(d_angle > 0){
          d_angle = d_angle - 360;
        } else {
          d_angle = d_angle + 360;
        }
      }


      let v = {
        lat:   (position.lat   - currentPos.lat),
        lon:   (position.lon   - currentPos.lon),
        angle: ( d_angle ),
      }
      // 移動だけ
      var move = function(setPos, v, prev, from, sumtime){
        let now = Date.now()
        let dt = now  - prev;
        sumtime = sumtime + dt;

        // end animation
        if(sumtime > _duration){
          return;
        }

        let d = dt/_duration;
				let a = from.angle + v.angle * d;
        let p = {
          lat:   from.lat + v.lat * d,
          lon:   from.lon + v.lon * d,
          angle: isNaN(a)? 0 : a
        }
        //動かす
        setPos(p);

        //Animation描画
        requestAnimationFrame(()=>move(setPos, v, now, p, sumtime));
      }

      //Animation描画
      requestAnimationFrame(()=>move(setCurrentPos, v, Date.now(), currentPos, 0));
    }
  },[position, animate_flg])

  // TooltipとNameの表示によるアイコン位置の調整
  useEffect(() => {
		// Focusかつ、Name非表示
		// Focus時
    if (focus && showTooltip ) {
      setPosDiff(posDiffFocusTooltip);
      setWindLine({deviceId: id});
			//console.log("focustool", name)
      return;
    } else if (focus && showMarker){
      setPosDiff(posDiffFocus);
      setWindLine({deviceId: id});
			//console.log("focus", name)
      return;
		} else if (showTooltip) {
      setPosDiff(posDiffTooltip);
			//console.log("tooltip")
      return;
		}else if (showMarker) {
      setPosDiff(posDiffName);
			//console.log("showname", name)
      return;
    } else {
      setPosDiff(posDiffDefault);
			//console.log("default", name)
      return;
    }
 
   //setWindLine(null);
  },[showTooltip, setWindLine, showName, id, focus, showMarker])

  //console.log("xxxxx:", showMarker, name)

  // focusが外れたらTooltipを閉じる
  useEffect(() => {
    if (!focus) {
      setShowTooltip(false);
    }
  },[focus])


	const onClick = (showTooltip) =>{
		setShowTooltip(!showTooltip);
		handleClickMarker(id);
	}


  // 一つのMarkerでname, tooltip, icon, focus_iconを表示しているので、表示・非表示の切り替えで位置調整が必要。
  // 上記を別々のMarkerで表示するとその必要がなくなる可能性あり。(しかしMarkerの数が増えるのでパフォーマンスに影響がでる可能性あり。)
  return(
    <>
    <Marker longitude={currentPos.lon} latitude={currentPos.lat}>

      {((showName && showMarker)) && (
        <>
        <div className={greenBack()?"tooltip-gb":"tooltip"}
             onClick={()=>(setShowTooltip(!showTooltip))}
             style={{ fontSize: `${font_size()}px` }} // ここで動的にフォントサイズを適用
        >
        {rank && (
        <div className={rank==1 ? greenBack()? "tooltip-ranking-roadrace-gb":"tooltip-ranking-roadrace": greenBack()? "tooltip-ranking-gb":"tooltip-ranking"}
          style={{ fontSize: `${font_size()}px`, left: rank==1? `${-font_size()*3-1}px` : `${-font_size()-1}px` }} // ここで動的にフォントサイズを適用
        >
          {rank === 1 ? "Leader" : rank < 10 ? "\u00A0"+rank+"\u00A0" : `${rank}`}
        </div>
        )}
          { no }
          {showTooltip && (
            <>
              <div className="tooltip-name">{name}</div>
              <div className="tooltip-speed">SPD:{speed}km/h</div>
              <div className="tooltip-time">UPD:{time}</div>
            </>
          )}
        </div>
        </>
      )}
    </Marker>

    <Marker longitude={currentPos.lon} latitude={currentPos.lat}>
      { category == 1 && 
      
        marker_type()==2?
          <IconSphere offset={posDiff} rotate={currentPos.angle - bearing} color={color} focus={focus} showMarker={showMarker} emergency={emergency} onClick={() => onClick()} /> 
          :
          <IconArrow offset={posDiff} rotate={currentPos.angle - bearing} color={color} focus={focus} showMarker={showMarker} emergency={emergency} onClick={() => onClick()} /> 
      }
      { category == 8 && <IconSup offset={posDiff} rotate={currentPos.angle - bearing} color={color} focus={focus} onClick={() => (setShowTooltip(!showTooltip))} /> }

    </Marker>

    </>
  );
}

export default React.memo(Animation);
