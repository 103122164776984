import * as React from 'react';
import {useState, useEffect, useCallback} from 'react';
import Grid from '@mui/material/Grid';
import {is_mode} from '../../initialize/initialize.js';
import API from '../../initialize/API.js';
import {trim_name, calcDelayTime} from '../../helpers';

//rankingリスト用
var g_devices = []

//距離と速度からタイム差を計
var g_ranks = [];
let max_lap = 0;

export default function SidePanelRoadRace( props ) {
  const { devices, heat, handleChangeSelectedDevice, course, setRanking } = props;
  const [rankings, setRankings] = useState([]);
  const [delayedTime, setDelayedTime] = useState(null);//最後に選択したデバイス
	let intervalRanking= React.useRef();

	useEffect(() => {
		g_devices = devices

		//ischeckedの更新用
		let out = []
		rankings.forEach( r => {
			g_devices.forEach( d => {
				if(d.id == r.id){
					r.ischecked = d.ischecked
					r.showMarker = d.showMarker
          r.speed_5s = d.speed_5s
					out.push( r )
				}
			})
		})
		setRankings( out );
    g_ranks = out;

	},[devices]);

	//ランキングリストを一定周期で取得する
	useEffect(() => {
		if(is_mode() == "live" && heat){
		clearInterval( intervalRanking.current );
		intervalRanking =  setInterval(() => {
			let onSuccess = (res) => {
				if(!res.data)
					return
				if(!res.data.Ranklist)
					return
				let out = []
				let r_out = []
				let max_id=0;
				let max_spd=0;
				max_lap=0;
				//max speed search
				g_devices.forEach( d => {
					if(d.category == 1){
						if(d.speed_5s > max_spd){
							max_id = d.id;
							max_spd = d.speed_5s;
						}
					}
				})
				let count = 0;
				let dist  = 0;
				let spd  = 0;
				res.data.Ranklist.forEach( r => {
					g_devices.forEach( d => {
						if(d.athlete_id == r.athlete_id){
							let row = {
								"id": d.id,
								"is_max": max_id==d.id ? true : false,
								"showMarker": d.showMarker,
								"rank": r.rank,
								"sailno": d.no,
								"nation": d.nation,
								"firstname": d.firstname,
								"club": d.club,
								"speed_5s": d.speed_5s,
								"fwd_dist": r.fwd_dist,
								"nation": d.nation,
								"is_goal": r.is_goal,
								"name": d.nation=="JPN"? d.lastname : d.lastname,
								"ischecked": d.ischecked,
								"lap": r.lap,
								"color_code": d.color_code,
							}
							out.push( row )
              if(r.rank==1){
							  r_out.push( row )
              }
              if(max_lap < r.lap){
                max_lap = r.lap
                console.log("max_lap:",max_lap)
              }

							//checkedのユーザー数をカウント
							if(d.ischecked && count < 2){
								dist = r.fwd_dist - dist;
								spd = d.speed_5s;
								count=count+1;
							}
						}
					})
				})
				setRankings( out );
		    setRanking( r_out );
        g_ranks = out;
				
				//タイム差を更新
				if(count > 0){
					setDelayedTime( calcDelayTime(dist, spd, 40) )
				}else{
					setDelayedTime( false )
				}
			}
			let rank_host = process.env.REACT_APP_RANKING_URL;
			API.getRankingWithHeat({ onSuccess: onSuccess, host: rank_host,  heat_id: heat.id })
		} ,5000);
		}
	},[heat]);


	const goal = (
		<img className={"goal-flag"} src={"/goal_flag.png"}  alt={"goal"} />
	)

	const side_panel_ranking = (
		<>

    { course ? course.lap > 1 && (
			<div className={'lap-head'} style={{ display: "flex", alignItems: "center" }}>
				<div className={"lap-disp"} > LAP &nbsp;&nbsp;&nbsp; <span style={{color:"rgba(255, 127, 80, 1)"}}>{g_ranks.length > 0 ? ( Number(course.lap)-1 < Number(max_lap)+1 ? "FINAL" : max_lap+1):""}</span> { g_ranks.length > 0 ? "/"+ course.lap : "" }  </div>
			</div>
      ):<></>
    }

      <Grid container style={{ fontWeight: 'bold', padding: '10px 0' }}>
        <Grid item xs={1}>&nbsp;</Grid>  {/* 左側の空白 */}
        <Grid item xs={1}>&nbsp;</Grid>  {/* 左側の空白 */}
        <Grid item xs={4}>Name</Grid>
        <Grid item xs={2}>No#</Grid>
        <Grid item xs={2}>
          Spd<span style={{ fontSize: '8px' }}>(km/h)</span>
        </Grid>
        <Grid item xs={2}>Time</Grid>
      </Grid>

			{g_ranks.length > 0 ? (
				g_ranks.map((r) => (
          r.showMarker && (
            <div key={r.id}>
            <button key={r.id} className={r.ischecked ? 'btn-athlete-selected':'btn-athlete'} onClick={() => { handleChangeSelectedDevice(r.id); }} >
              <Grid container >
                <Grid  item xs={1}>
                  <span className={'ranking-rank'}>{r.rank}</span>
                </Grid>
                <Grid  item xs={1}>
                  <img
                    style={{border: `2pt solid ${r.color_code}`}}
                    className={'team-flag'}
                    src={`/j-pro/${r.club}.png`}
                    alt={r.club}
                    onError={(e) => {
                      e.target.src = '/j-pro/default.png';
                    }}
                  />

                </Grid>
                <Grid  item xs={4}>
                  <span className={'ranking-firstname'}>{/*r.fwd_dist*/trim_name(r.name, 16)}</span>
                </Grid>
                <Grid  item xs={2}>
                  <span className={r.ischecked ? 'ranking-athlete-no-selected' : 'ranking-athlete-no'}>   {r.sailno}</span>
                </Grid>
                <Grid  item xs={2}>
                  <span style={{ textAlign: 'center' }} className={r.is_max? 'ranking-spd-max' : 'ranking-spd'} > {r.speed_5s} </span>
                </Grid>
                <Grid  item xs={2}>
                  <span className={'ranking-dist'}> {r.is_goal ? goal : ( r.rank==1 ? "Leader" : calcDelayTime(r.fwd_dist, r.speed_5s, 40) )}</span>
                </Grid>
              </Grid>
            </button>
            </div>
          )//showMarker
				))
			) : (
				<>no data.</>
			)}
		</>
	);

  return (
		<>
		{side_panel_ranking}
		</>
	)
}


/*
		{
			delayedTime && (
				<div className="delayed-time">
						先頭集団との差<br />
						{delayedTime}
				</div>
			)
		}

*/
