import * as React from 'react';
import {wsURL, getHeatId} from './initialize.js';
import {calc_distance, convertGpsTime, to_d} from '../helpers';
import { isAthlete } from './device_helpers.js';

// WebSocketの初期化、接続
var isOnline = false;
var websocket;
var intervalWSCheck = null;
let updWebsocketTime = null;
var stop= false;
var g_devices;
var g_setDevices;
var g_setOnline;
var g_token;
var g_delay = 0;

export function setDelay(liveDelay){
    if(isOnline){
      const message = JSON.stringify({ live_delay: liveDelay }); // JSON形式に変換
      websocket.send(message); // WebSocketで送信
    }
}


export function closeWebsocket(){
		stop = true
		clearInterval(intervalWSCheck);
		intervalWSCheck = null;
		websocket.onopen = null;
		websocket.onmessage = null;
		g_setOnline(null);
		websocket.close();
		console.log("ws closed.");
		isOnline = false;
}

export function reInitializeWebsocket(delay){
	initializeWebsocket(g_devices, g_setDevices, g_setOnline, g_token)
	g_delay = delay;
}

export function initializeWebsocket(devices, setDevices, setOnline, token){
		stop = false;
		g_devices = devices;
		g_setDevices = setDevices;
		g_setOnline = setOnline;
		g_token = token;
		updWebsocketTime = new Date();
    console.log("[WS Endpoint] "+wsURL());

		//Websocketデータの受信をチェック
		let checkWebsocketOnline =() => {
			if(stop)
				return
			let now_t = new Date();
			console.log(now_t-updWebsocketTime)
			if( now_t-updWebsocketTime > 15000 ) {
				clearInterval(intervalWSCheck);
				intervalWSCheck = null;
				websocket.onopen = null;
				websocket.onmessage = null;
				websocket.close();
				console.log("ws closed.");
				setOnline(null);
				isOnline = false;
				//alert("Can not recieve data. Connection close. 再接続します。")
				console.log("Can not recieve data. Connection close. 再接続します。")
				initializeWebsocket(devices, setDevices, setOnline, token);
			}
		}


    if(!isOnline){
      isOnline = true;
      websocket = new WebSocket(wsURL());
    }

    websocket.onopen = function(event){
      console.log("ws opened.");
      isOnline = true;
      setOnline({online: isOnline});
      websocket.send(token);
			if(g_delay > 0){
				setDelay(g_delay)
				g_delay = 0
			}
      //V5
      const message = JSON.stringify({ heat_id: getHeatId() }); // JSON形式に変換
      websocket.send(message); // WebSocketで送信
			//WSのデータ受信状態をチェック
			if(!intervalWSCheck){//２周動作チェック
				intervalWSCheck = setInterval(() => {
					checkWebsocketOnline();
				}, 5000);
			}else{
				console.log("Check interval already running")
			}
    }

    websocket.onmessage = function (event) {
			updWebsocketTime = new Date();//online確認用
      let data = JSON.parse(event.data);
      //console.log("websocket on message:", data);
			if(data.type==="gps"){
      	move(data, devices, setDevices);
			}
    }

    //Websocket close
    websocket.onclose = function (event) {
      isOnline = false;
      setOnline(null);
      console.log("WebSocket closed.");
      //alert("コネクションが切断(1)。3秒後に再接続します")
      console.log("コネクションが切断(1)。3秒後に再接続します")
      setTimeout(() => {
				if(stop)
					return
        initializeWebsocket(devices, setDevices, setOnline, token)
      }, 3000);
    }
}


// デバイス単体の動作制御
function move(data, devices, setDevices){

  let device = devices.get(data.id)

  if(!device)
    return

  //Update device using websocket data
  device.angle = Math.atan2((data.lat-device.lat) , -(data.long-device.lon)) * ( 180 / Math.PI ) + 90 + 180
  device.lat = data.lat;
  device.lon = data.long;
	device.updSpeed(data.spd, data.spd_10s, data.spd_5s);

	device.emergency = data.emergency

  device.time = timeFormatGpsToLocal(data);

  //航跡ライン用
  device.pushGps(data.lat, data.long);

  //update devices
  //setDevices(devices => new Map([...devices, [data.id, device]]));
	const updatedMap = new Map(devices);
	updatedMap.set(data.id, device);
	setDevices(updatedMap);
}


function timeFormatGpsToLocal(gps){
  let d = new Date();
  let t = gps.time;
  d.setHours(t.slice(0, 2));
  d.setMinutes(t.slice(2, 4));
  d.setSeconds(t.slice(4, 9));
  d.setTime(d.getTime() + 1000*60*60*9);
  return d.toLocaleTimeString();
}


//debug
function timeFormatGpsToLocalTime(gps){
  let d = new Date();
  let t = gps.time;
  d.setHours(t.slice(0, 2));
  d.setMinutes(t.slice(2, 4));
  d.setSeconds(t.slice(4, 9));
  d.setTime(d.getTime() + 1000*60*60*9);
  return d;
}
